import { NgModule } from '@angular/core';
import { LinkDirective } from './link.directive';

@NgModule({
  imports: [],
  declarations: [LinkDirective],
  providers: [],
  exports: [LinkDirective],
})
export class LinkModule {}
