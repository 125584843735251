import { Injectable } from '@angular/core';
import {
  IMediaSize,
  TMediaResponsiveConfig,
} from '@x/common/media-thumbnail/media-thumbnail.config';
import { ResponsiveService } from '@x/common/responsive';
import { EMPTY, Observable, of } from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class MediaThumbnailService {
  constructor(private responsiveService: ResponsiveService) {}

  observeDimensions$(
    responsiveConfig: TMediaResponsiveConfig | undefined,
    defaultWidth: number | undefined,
    defaultHeight: number | undefined,
  ): Observable<IMediaSize> {
    let defaultMediaSize: IMediaSize | undefined;

    if (responsiveConfig && this.responsiveService.currentSize) {
      const currentSizeConfig = responsiveConfig[this.responsiveService.currentSize];
      defaultMediaSize = { width: currentSizeConfig.width, height: currentSizeConfig.height };
    } else if (defaultWidth && defaultHeight) {
      defaultMediaSize = { width: defaultWidth, height: defaultHeight };
    }

    if (!defaultMediaSize) return EMPTY;

    if (!responsiveConfig) return of(defaultMediaSize);

    return this.responsiveService.observeBreakpoints().pipe(
      map(({ size }) => size),
      filter(Boolean),
      map((size) => responsiveConfig?.[size] ?? (defaultMediaSize as IMediaSize)),
      startWith(defaultMediaSize),
    );
  }
}
