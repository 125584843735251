import {
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';

@Directive({
  selector: '[xObserveVisibility]',
  standalone: true,
})
export class ObserveVisibilityDirective implements OnInit, OnDestroy {
  observer!: IntersectionObserver;
  private previousIsVisible: boolean | null = null;

  @Input() emitVisibleOnce = false;

  @Output()
  isVisible = new EventEmitter<boolean>();

  constructor(private el: ElementRef<HTMLElement>) {}

  ngOnInit(): void {
    // Create observer with fine-grained thresholds
    this.observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const isCurrentlyVisible = entry.isIntersecting && entry.intersectionRatio >= 0.5;

          if (this.emitVisibleOnce && this.previousIsVisible) return;

          // Emit if visibility status changed
          if (isCurrentlyVisible !== this.previousIsVisible) {
            this.previousIsVisible = isCurrentlyVisible; // Update the state
            this.isVisible.emit(isCurrentlyVisible);
          }
        });
      },
      {
        // Generate a fine-grained range of thresholds
        threshold: [0, 0.25, 0.5, 0.75, 1],
      },
    );
    this.observer.observe(this.el.nativeElement);
  }

  ngOnDestroy(): void {
    this.observer.disconnect();
  }
}
