@if (slides.length) {
  @if (wr.isServer) {
    <div class="ssr-slides">
      @for (slide of slides; let index = $index; track slide.trackByKey || index) {
        @if (slidesPerView && index < slidesPerView) {
          <div class="slide" style="">
            <ng-container
              *ngTemplateOutlet="slideTemplate; context: { $implicit: slide, index }"
            ></ng-container>
          </div>
        }
      }
    </div>
  } @else {
    <swiper-container #SWIPER_CONTAINER init="false">
      @for (slide of slides; let index = $index; track slide.trackByKey || index) {
        <swiper-slide>
          <ng-container
            *ngTemplateOutlet="slideTemplate; context: { $implicit: slide, index }"
          ></ng-container>
        </swiper-slide>
      }
    </swiper-container>

    @if (useDefaultNavigation) {
      <div class="nav swiper-button-prev" #NAV_PREV></div>
      <div class="nav swiper-button-next" #NAV_NEXT (click)="nextNav()"></div>
    }
  }
}
