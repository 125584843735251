import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { VideoCropMode } from '@x/common/cloudinary';
import {
  IMediaSize,
  TMediaResponsiveConfig,
} from '@x/common/media-thumbnail/media-thumbnail.config';
import { MediaThumbnailService } from '@x/common/media-thumbnail/media-thumbnail.service';
import { BehaviorSubject, Subscription, distinctUntilChanged } from 'rxjs';
import { startWith, tap } from 'rxjs/operators';

@Component({
  selector: 'x-html5-player',
  template: `
    @if (videoSize$ | async; as videoSize) {
      <video
        #video
        xObserveVisibility
        (isVisible)="isVisible($event)"
        [xCloudVideo]="url"
        (canplay)="mediaLoaded.emit()"
        [width]="videoSize.width"
        [height]="videoSize.height"
        [autoplay]="autoplay"
        [controls]="controls"
        [muted]="muted"
        [loop]="loop"
        [preload]="preload"
        [class.fluid]="fluid"
        [crop]="crop"
        [poster]="url | cloudImage: { width: videoSize.width, height: videoSize.height }"
        [quality]="quality"
      ></video>
    }
  `,
  host: { class: 'x-html5-player' },
})
export class Html5PlayerComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();

  @Input() width?: number;
  @Input() title?: string;
  @Input() height?: number;
  @Input() url?: string;
  @Input() autoplay: boolean = true;
  @Input() controls: boolean = false;
  @Input() muted: boolean = true;
  @Input() loop: boolean = true;
  @Input() playsInline: boolean = true;
  @Input() preload: 'none' | 'metadata' | 'auto' = 'auto';
  @Input() fluid: boolean = true;
  @Input() id?: string;
  @Input() blurHash?: string;
  @Input() crop?: VideoCropMode;
  @Input() responsiveConfig?: TMediaResponsiveConfig;
  @Input() quality?: number | string;

  @Output() mediaLoaded = new EventEmitter<any>();

  _video: HTMLVideoElement;
  @ViewChild('video') set videoEl(video: ElementRef<HTMLVideoElement>) {
    if (!video) return;

    this._video = video.nativeElement;
    this._video.playsInline = this.playsInline;
  }

  get video() {
    return this._video;
  }

  videoSize$ = new BehaviorSubject<null | Partial<IMediaSize>>(null);

  constructor(private mediaThumbnailService: MediaThumbnailService) {}

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit(): void {
    this.subscription.add(
      this.mediaThumbnailService
        .observeDimensions$(this.responsiveConfig, this.width, this.height)
        .pipe(
          distinctUntilChanged(),
          startWith({ width: this.width, height: this.height }),
          tap((videoSize) => this.videoSize$.next(videoSize)),
        )
        .subscribe(),
    );
  }

  private async playVideo() {
    if (!this.video) return;

    this.video.muted = true;
    await this.video.play();
  }

  private pauseVideo() {
    if (!this.video) return;
    this.video.pause();
  }

  async isVisible($event: boolean) {
    if ($event) {
      await this.playVideo();
      return;
    }

    this.pauseVideo();
  }
}
