import { Inject, Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { WindowRef } from '@x/common/browser';
import {
  CloudinaryUrl,
  ImageTransformationOptions,
  constructCloudinaryUrl,
  deconstructCloudinaryUrl,
  isCloudinaryUrl,
  transformPathFromOptions,
} from '@x/common/cloudinary/cloudinary';
import {
  CLOUDINARY_MODULE_CONFIG,
  CloudinaryModuleConfig,
} from '@x/common/cloudinary/cloudinary.config';

@Pipe({
  name: 'cloudImage',
  standalone: true,
})
export class CloudImagePipe implements PipeTransform {
  private defaultOptions: ImageTransformationOptions = {
    format: 'auto',
    quality: 'auto',
    crop: 'fill',
    dpr: Math.min(2, Math.round(this.windowRef.devicePixelRatio)),
    start_offset: 0,
  };

  constructor(
    private sanitizer: DomSanitizer,
    @Inject(CLOUDINARY_MODULE_CONFIG) private config: CloudinaryModuleConfig,
    private windowRef: WindowRef,
  ) {}

  transform(url: string | undefined, options: ImageTransformationOptions): SafeUrl | string {
    if (!url) {
      return ''; // If URL is missing, return empty string
    }

    let cloudinaryUrl: CloudinaryUrl | null;

    if (isCloudinaryUrl(url)) {
      // If the URL is already a Cloudinary URL, deconstruct it
      cloudinaryUrl = deconstructCloudinaryUrl(url);
    } else {
      // If not, treat it as a public ID and construct Cloudinary URL from scratch
      cloudinaryUrl = {
        cloud_name: this.config.cloudName,
        type: 'image',
        mode: 'fetch',
        public_id: url,
      };
    }

    // Add the transformation options, including DPR fallback
    const mergedOptions: ImageTransformationOptions = {
      ...this.defaultOptions,
      ...options,
    };

    const transformedUrl = constructCloudinaryUrl({
      ...cloudinaryUrl,
      transform: transformPathFromOptions(mergedOptions),
    });

    // Sanitize the URL before returning it to the template
    return this.sanitizer.bypassSecurityTrustUrl(transformedUrl);
  }
}
