import { NgTemplateOutlet } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { WindowRef } from '@x/common/browser';
import Swiper from 'swiper';
import { register, SwiperContainer } from 'swiper/element/bundle';
import { SwiperOptions } from 'swiper/types/swiper-options';

register();

export interface ISwiperSLide extends Record<any, any> {
  trackByKey?: string | number;
}

@Component({
  selector: 'x-swiper',
  standalone: true,
  imports: [NgTemplateOutlet],
  templateUrl: './swiper.component.html',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'x-swiper' },
})
export class SwiperComponent<T extends ISwiperSLide> implements AfterViewInit {
  private _swiperOptions: SwiperOptions = {
    navigation: true,
    slidesPerView: 1,
    on: {
      init: () => {
        this.onInit.emit(this.swiper);
      },
      slideChange: () => {
        this.onSlideChange.emit(this.swiper);
      },
      activeIndexChange: () => {
        this.onActiveIndexChange.emit(this.swiper);
      },
      update: () => {
        this.onUpdate.emit(this.swiper);
      },
      breakpoint: (swiper, breakpointOptions) => {
        this.onBreakpoint.emit(breakpointOptions);
      },
    },
  };

  @Input() set swiperOptions(options: SwiperOptions) {
    this._swiperOptions = { ...this._swiperOptions, ...options };
  }

  @Input() useDefaultNavigation: boolean = true;

  @Input() showPreview: boolean = true;

  get swiperOptions() {
    return this._swiperOptions;
  }

  get slidesPerView() {
    return this.swiperOptions.slidesPerView as number;
  }

  @ViewChild('SWIPER_CONTAINER') swiperElement?: ElementRef<SwiperContainer>;
  get swiperContainer() {
    return this.swiperElement?.nativeElement;
  }
  get swiper() {
    return this.swiperContainer?.swiper;
  }

  private _slides: T[] = [];
  @Input({ required: true }) set slides(slides: T[]) {
    this._slides = [...slides];
    setTimeout(() => this.swiper?.update(), 500);
  }
  get slides(): T[] {
    return this._slides;
  }

  @Input({ required: true }) slideTemplate: TemplateRef<any>;
  @Input() previewSize: number = 0.2;

  @Output() onSlideChange = new EventEmitter<Swiper>();
  @Output() onInit = new EventEmitter<Swiper>();
  @Output() onUpdate = new EventEmitter<Swiper>();
  @Output() onBreakpoint = new EventEmitter<SwiperOptions>();
  @Output() onActiveIndexChange = new EventEmitter<Swiper>();
  @Output() onReachEnd = new EventEmitter<Swiper>();
  @Output() nextClickEvent = new EventEmitter<Swiper>();
  @Output() prevClickEvent = new EventEmitter<Swiper>();

  @ViewChild('NAV_PREV') navPrev?: ElementRef<HTMLElement>;
  @ViewChild('NAV_NEXT') navNext?: ElementRef<HTMLElement>;

  constructor(public wr: WindowRef) {}

  ngAfterViewInit(): void {
    if (this.wr.isBrowser) this.init();
  }

  init() {
    if (!this.swiperContainer) return;

    // preview of next slide
    if (this.showPreview) {
      this._swiperOptions = this.updateSwiperOptionsSlidesPerView(this._swiperOptions);
    }

    //ad navigation elements
    if (this.useDefaultNavigation) {
      this.swiperOptions = {
        navigation: {
          enabled: true,
          prevEl: this.navPrev?.nativeElement,
          nextEl: this.navNext?.nativeElement,
        },
      };
    }

    Object.assign(this.swiperContainer, this.swiperOptions);

    this.swiperContainer.initialize();
  }

  nextNav() {
    this.nextClickEvent.emit(this.swiper);
  }

  linkThumbs(swiper: Swiper) {
    if (this.swiperContainer) {
      this.swiperContainer.thumbs = {
        swiper: swiper,
      };
    }
  }

  private updateSwiperOptionsSlidesPerView(options: SwiperOptions): SwiperOptions {
    const slidesPerView = options.slidesPerView as number;
    if (slidesPerView > 1 && this.slides.length > slidesPerView) {
      options.slidesPerView = Math.floor(slidesPerView) + this.previewSize;
    }

    if (options.breakpoints) {
      for (const [bp, bpOptions] of Object.entries(options.breakpoints)) {
        options.breakpoints[bp] = this.updateSwiperOptionsSlidesPerView(bpOptions as SwiperOptions);
      }
    }

    return options;
  }
}
