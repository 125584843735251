import { LocationStrategy } from '@angular/common';
import { Attribute, Directive, ElementRef, HostBinding, Input, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { hasFragment, isAbsolute, isFragment } from '@x/common/utils';

@Directive({
  selector: 'a[xLink]',
})
export class LinkDirective extends RouterLink {
  @Input() set xLink(link: string | null | undefined | any[]) {
    this.setLink(link);
  }

  @HostBinding('attr.rel')
  rel: string;

  constructor(
    router: Router,
    route: ActivatedRoute,
    @Attribute('tabindex')
    tabIndexAttribute: string | null | undefined,
    renderer: Renderer2,
    el: ElementRef,
    locationStrategy: LocationStrategy,
  ) {
    super(router, route, tabIndexAttribute, renderer, el, locationStrategy);
  }

  setLink(url: string | null | undefined | any[], openInNewTab?: boolean) {
    if (url) {
      if (Array.isArray(url)) {
        this.routerLink = url;
      } else if (isAbsolute(url)) {
        // external links use the navigate-external component for handling redirect
        this.href = url;
        this.routerLink = ['/external'];
        this.queryParams = { url: url };
        this.rel = 'external';
        this.target = openInNewTab ? '_blank' : '_self';
      } else if (isFragment(url)) {
        // this is required to stay on te same page and only launch the dialog service
        this.routerLink = [];
        this.fragment = url.replace('#', '');
      } else if (hasFragment(url)) {
        const [urlWithoutFragment, fragment] = url.split('#');
        this.routerLink = urlWithoutFragment;
        this.fragment = fragment;
      } else {
        // normal routerLink
        this.routerLink = url;
      }
    } else {
      this.routerLink = null;
      this.href = null;
    }
  }
}
